import { navigateToApigeeLogin } from '@tawal/utils'
import { useEffect } from 'react'

function IndexPage() {
  useEffect(() => {
    navigateToApigeeLogin()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default IndexPage
